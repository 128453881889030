<template>
  <div v-loading="loading">
    <div>
      <el-descriptions :column="1" border size="mini" label-class-name="descriptions-label-box" content-class-name="descriptions-content-box" >
        <el-descriptions-item label="设备名称">{{ data.deviceName || "-" }}</el-descriptions-item>
        <el-descriptions-item label="设备编号">{{ data.code || "-" }}</el-descriptions-item>
        <el-descriptions-item label="告警时间">{{ data.createTime || "-" }}</el-descriptions-item>
        <el-descriptions-item label="告警信息">
          <div v-if="data.warning.length > 0">
            <span v-if="data.warning[0]">总压过高一级告警 </span>
            <span v-if="data.warning[1]">总压过高二级告警 </span>
            <span v-if="data.warning[2]">总压过高三级告警 </span>
            <span v-if="data.warning[3]">总压过低一级告警 </span>
            <span v-if="data.warning[4]">总压过低二级告警 </span>
            <span v-if="data.warning[5]">总压过低三级告警 </span>
            <span v-if="data.warning[6]">单体过高一级告警 </span>
            <span v-if="data.warning[7]">单体过高二级告警 </span>
            <span v-if="data.warning[8]">单体过高三级告警 </span>
            <span v-if="data.warning[9]">单体过低一级告警 </span>
            <span v-if="data.warning[10]">单体过低二级告警 </span>
            <span v-if="data.warning[11]">单体过低三级告警 </span>
            <span v-if="data.warning[12]">高段压差过高一级告警 </span>
            <span v-if="data.warning[13]">高段压差过高二级告警 </span>
            <span v-if="data.warning[14]">高段压差过高三级告警 </span>
            <span v-if="data.warning[15]">中段压差过高一级告警 </span>
            <span v-if="data.warning[16]">中段压差过高二级告警 </span>
            <span v-if="data.warning[17]">中段压差过高三级告警 </span>
            <span v-if="data.warning[18]">低段压差过高一级告警 </span>
            <span v-if="data.warning[19]">低段压差过高二级告警 </span>
            <span v-if="data.warning[20]">低段压差过高三级告警 </span>
            <span v-if="data.warning[21]">辅助压差过高一级告警 </span>
            <span v-if="data.warning[22]">辅助压差过高二级告警 </span>
            <span v-if="data.warning[23]">辅助压差过高三级告警 </span>
            <span v-if="data.warning[24]">充电电流过高一级告警 </span>
            <span v-if="data.warning[25]">充电电流过高二级告警 </span>
            <span v-if="data.warning[26]">充电电流过高三级告警 </span>
            <span v-if="data.warning[27]">放电电流过高一级告警 </span>
            <span v-if="data.warning[28]">放电电流过高二级告警 </span>
            <span v-if="data.warning[29]">放电电流过高三级告警 </span>
            <span v-if="data.warning[30]">反充电流过高一级告警 </span>
            <span v-if="data.warning[31]">反充电流过高二级告警 </span>
            <span v-if="data.warning[32]">反充电流过高三级告警 </span>
            <span v-if="data.warning[33]">SOC值过低一级告警 </span>
            <span v-if="data.warning[34]">SOC值过低二级告警 </span>
            <span v-if="data.warning[35]">SOC值过低三级告警 </span>
            <span v-if="data.warning[36]">SOH值过低一级告警 </span>
            <span v-if="data.warning[37]">SOH值过低二级告警 </span>
            <span v-if="data.warning[38]">SOH值过低三级告警 </span>
            <span v-if="data.warning[39]">继电器温度过高一级告警 </span>
            <span v-if="data.warning[40]">继电器温度过高二级告警 </span>
            <span v-if="data.warning[41]">继电器温度过高三级告警 </span>
            <span v-if="data.warning[42]">充电温度过高一级告警 </span>
            <span v-if="data.warning[43]">充电温度过高二级告警 </span>
            <span v-if="data.warning[44]">充电温度过高三级告警 </span>
            <span v-if="data.warning[45]">放电温度过高一级告警 </span>
            <span v-if="data.warning[46]">放电温度过高二级告警 </span>
            <span v-if="data.warning[47]">放电温度过高三级告警 </span>
            <span v-if="data.warning[48]">充电温度过低一级告警 </span>
            <span v-if="data.warning[49]">充电温度过低二级告警 </span>
            <span v-if="data.warning[50]">充电温度过低三级告警 </span>
            <span v-if="data.warning[51]">放电温度过低一级告警 </span>
            <span v-if="data.warning[52]">放电温度过低二级告警 </span>
            <span v-if="data.warning[53]">放电温度过低三级告警 </span>
            <span v-if="data.warning[54]">温差过大一级告警 </span>
            <span v-if="data.warning[55]">温差过大二级告警 </span>
            <span v-if="data.warning[56]">温差过大三级告警 </span>
            <span v-if="data.warning[57]">均衡温度过高一级告警 </span>
            <span v-if="data.warning[58]">均衡温度过高二级告警 </span>
            <span v-if="data.warning[59]">均衡温度过高三级告警 </span>
            <span v-if="data.warning[60]">绝缘电阻过低一级告警 </span>
            <span v-if="data.warning[61]">绝缘电阻过低二级告警 </span>
            <span v-if="data.warning[62]">绝缘电阻过低三级告警 </span>
            <span v-if="data.warning[63]">总压采样异常一级告警 </span>
            <span v-if="data.warning[64]">总压采样异常二级告警 </span>
            <span v-if="data.warning[65]">总压采样异常三级告警 </span>
            <span v-if="data.warning[66]">静置温度过高一级告警 </span>
            <span v-if="data.warning[67]">静置温度过高二级告警 </span>
            <span v-if="data.warning[68]">静置温度过高三级告警 </span>
            <span v-if="data.warning[69]">静置温度过低一级告警 </span>
            <span v-if="data.warning[70]">静置温度过低二级告警 </span>
            <span v-if="data.warning[71]">静置温度过低三级告警 </span>
            <span v-if="data.warning[72]">主正接触器无法吸合 </span>
            <span v-if="data.warning[73]">主正接触器粘连故障 </span>
            <span v-if="data.warning[74]">主负接触器无法吸合 </span>
            <span v-if="data.warning[75]">主负接触器粘连故障 </span>
            <span v-if="data.warning[76]">充电接触器无法吸合 </span>
            <span v-if="data.warning[77]">充电接触器粘连故障 </span>
            <span v-if="data.warning[78]">预热接触器无法吸合 </span>
            <span v-if="data.warning[79]">预热接触器粘连故障 </span>
            <span v-if="data.warning[80]">风扇接触器无法吸合 </span>
            <span v-if="data.warning[81]">风扇接触器粘连故障 </span>
            <span v-if="data.warning[82]">预充接触器无法吸合 </span>
            <span v-if="data.warning[83]">预充接触器粘连故障 </span>
          </div>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>
<script>
// import { parseWarningData } from "@/utils/device";
import { getHistoryAlarm } from "@/api/device";

export default {
  props: {
    deviceInfo: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      loading: false,
      data: {
        deviceName: "-",
        code: "-",
        createTime: "-",
        warning: ""
      }
    }
  },
  created () {
    this.getDatas();
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;
        const res = await getHistoryAlarm({
          code: this.deviceInfo.code,
          page: 1,
          size: 1
        });

        const { deviceName, productId, createTime, warning } = res.data[0];

        if (warning.length < 84) {
          for (let i = warning.length; i < 84; i++) {
            warning = "0" + warning;
          }
        }

        const { warningData, count } = $utils.parseWarningData(warning);

        this.data = {
          deviceName,
          code: this.deviceInfo.code,
          createTime,
          warning: count > 0 ? warningData: []
        };
      } finally {
        this.loading = false;
      }
    },
    onClosed () {
      return true;
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .descriptions-label-box {
  width: 100px !important;
}
</style>